.woocommerce-cart .page-header .title {
	margin: 0;
}

.woocommerce a.remove {
	line-height: 0.9;
}

#add_payment_method table.cart img,
.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img {
	width: 64px;
	box-shadow: none;
}

#add_payment_method table.cart td.actions .coupon .input-text,
.woocommerce-cart table.cart td.actions .coupon .input-text,
.woocommerce-checkout table.cart td.actions .coupon .input-text {
	height: 42px;
	width: 140px;
}

.woocommerce .cart-collaterals,
.woocommerce-page .cart-collaterals {
	margin-top: 4rem;
}

.woocommerce ul#shipping_method li {
	&:before {
		display: none;
	}
}