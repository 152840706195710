.woocommerce-checkout .page-header .title {
	margin: 0;
}

#customer_details {
	margin-bottom: 4rem;

	> div {
		margin-top: 2rem;
	}
	
	h3 {
		font-size: 1.5rem;
	}
}

#add_payment_method #payment ul.payment_methods li,
.woocommerce-cart #payment ul.payment_methods li,
.woocommerce-checkout #payment ul.payment_methods li {
}

#add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after, #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::before, .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after, .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::before, .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after, .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::before {
	display: none;
}

.order-notes {
	font-weight: bold;
	margin-top: 1rem;
}

#place_order {
	margin-top: 2rem;
}