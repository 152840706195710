.woocommerce-page .block-free {
	font-size: 1rem;
}

.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message,
.woocommerce-page .woocommerce-error,
.woocommerce-page .woocommerce-info,
.woocommerce-page .woocommerce-message {

	.button {
		@include media-breakpoint-down(sm) {
			margin-bottom: 15px;
		}
	}
}

.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message,
.woocommerce-page .woocommerce-info,
.woocommerce-page .woocommerce-message {
	&:before {
		color: $color;
	}
}


.woocommerce-notices-wrapper {
	margin: 0 auto;
	padding: 0 $grid-gutter-width * 0.5;

	@each $breakpoint, $container-max-width in $container-max-widths {
		@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
			max-width: $container-max-width;
		}
	}
}

/*------------------------------------------------- Buttons */

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce a.button.alt,
.woocommerce button.button,
.woocommerce button.button.alt,
.woocommerce button[type=submit],
.woocommerce input.button {
	@extend .btn;
	@extend .squared;
	@extend .white;
	@extend .transparent;
	padding-left: 2rem;
	padding-right: 2rem;
	font-weight: normal;
}

.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover {
	background-color: darken($color, 10%);
}

#add_payment_method .wc-proceed-to-checkout,
.woocommerce-cart .wc-proceed-to-checkout,
.woocommerce-checkout .wc-proceed-to-checkout,
.woocommerce-cart .wc-proceed-to-checkout {
	padding: 0;
}


#add_payment_method .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.btn {
	width: 100%;
}

/*------------------------------------------------- Inputs */

.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
	@extend .form-control;
}

.woocommerce .search-field {
	@extend .form-control;
}


.disclaimer-popup {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: transparentize(white, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99;
	cursor: pointer;

	> h3 {
		background: transparentize($turq, 0.8);
		border: 1px solid $turq;
		padding: 2rem;
		text-transform: uppercase;
		font-weight: normal;
		text-align: center;
		
		span {
			font-size: 1rem;
		}
	}
}