.woocommerce div.product h2,
.woocommerce div.product h5 {
	font-size: 1.375rem;
	line-height: 1.375;
	font-weight: 400;
	text-transform: uppercase;
	margin-bottom: .5rem;
}

.woocommerce div.product .media {
	position: relative;
}

.woocommerce #content div.product div.images,
.woocommerce div.product div.images,
.woocommerce-page #content div.product div.images,
.woocommerce-page div.product div.images {
	float: none;
	width: 100%;
}

.woocommerce #content div.product div.summary,
.woocommerce div.product div.summary,
.woocommerce-page #content div.product div.summary,
.woocommerce-page div.product div.summary {
	float: none;

	@include media-breakpoint-up(md) {
		width: 50%;
	}
}

.woocommerce div.product p.price,
.woocommerce div.product span.price {
	margin-top: 4rem;
}

.woocommerce div.product form.cart {
	margin-top: 2rem;
}

.woocommerce div.product form.cart .variations select {
	@extend .form-select;
	width: auto;
}

.woocommerce .quantity .qty {
	width: 5rem;
	padding: 0.5rem;
	font-size: 1rem;
	height: 42px;
	border: 1px solid $color;
	border-radius: 0;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price {
	color: $turq;

}

.woocommerce-variation.single_variation {
	margin-bottom: 1rem;
}

.woocommerce div.product .downloads {
	margin: 2rem 0 4rem;

	.links {
		display: grid;
		grid-template-columns: repeat(3,auto);
		gap: .5rem;
		max-width: 600px;
	}
}

.woocommerce .woocommerce-notices-wrapper {
	a.button {
		&:hover {
			border: none;
			background: transparent;
			text-decoration: underline;
			color: $turq;
		}
	}
}

.woocommerce_custom_field {
	display: block;
	margin: 2rem 0;
	line-height: 1;
	text-transform: uppercase;

	span {
		font-weight: bold;
	}
}