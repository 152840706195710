.woocommerce-account {

	.page-header .title {
		margin: 0;
	}

	.woocommerce-MyAccount-content {
		margin-bottom: 140px;

		a:not(.btn):not(.button) {
			color: $color;
			text-decoration: underline;
		}
	}

	.woocommerce-info {
		border-top-color: $color;
	}

	.woocommerce-info::before {
		color: $color;
	}

	h3 {
		font-size: 1.5rem;
	}

	fieldset {
		padding: 20px;
		margin: 60px 0 30px;
		border: 1px solid $color;
	}

	legend {
		padding: 0;
	}

	input {
	}
}

.woocommerce-address-fields__field-wrapper {
	margin-bottom: 30px;
}

.woocommerce-account .woocommerce-MyAccount-navigation {
	margin-bottom: 60px;

	ul {
		padding: 0;
		margin: 0;
	}

	li {
		margin-bottom: 1em;
		
		&:before {
			display: none;
		}

		 &.is-active {
			 font-weight: bold;
		 }
	}
}

.woocommerce-account .addresses .title {
	h3 {
		float: none;
	}

	.edit {
		float: none;
		font-size: 15px;
	}
}

.woocommerce-Addresses {
	margin-top: 50px;
}

.woocommerce table.my_account_orders {
	font-size: 1em;
}

.woocommerce-Address {
	address {
		line-height: 1.4;
	}
}

.button[name=save_address] {
}

.woocommerce form.lost_reset_password,
.woocommerce form.login,
.woocommerce form.register {
	border: none;
	padding: 0;

	.form-row {
		padding: 0;
	}
}