header.header {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 999;
    padding: 0;
    transition: 0.25s ease-in-out;
    border-bottom: 1px solid rgba($border, 0.2);

    @include media-breakpoint-down(lg) {
        // position: relative;
    }

    .navbar {
        padding: 0;

        // LOGO
        .navbar-brand {
            display: flex;
            padding: 0 2rem 0 0;
            margin-right: auto;
            align-items: center;

            img {
                height: 2.5rem;
                width: auto;

                // @include media-breakpoint-down(xxl) {
                //     height: 2rem;
                // }

                @include media-breakpoint-down(xxl) {
                    height: 1.5rem;
                }

                @include media-breakpoint-down(md) {
                    height: 1.25rem;
                }
            }

            @include media-breakpoint-up(xxl) {
                width: 15%;
                margin-right: auto;
            }

            @include media-breakpoint-down(lg) {
                margin: 0 auto;
                padding: 0;
            }
        }
        // TOGGLERS
        .menu-toggler {
            display: flex;
            border: 0;
            color: $text;
            background: none;
            padding: 0;
            transition: 0.25s ease-in-out;
            align-items: center;
            cursor: pointer;
            flex-shrink: 0;
            // margin-left: 2rem;
            font-size: 0.875rem;

            @include media-breakpoint-down(lg) {
                width: 20%;
                margin-right: auto;
                font-size: 1rem;
            }

            i {

                &:hover {
                    color: inherit;
                }
            }
        }

        .right-togglers {
            display: flex;
            gap: .5rem;
            margin-left: 1.5rem;

            @include media-breakpoint-up(xxl) {
                width: 15%;
                margin-left: auto;
                justify-content: end;
                padding: 0 0 0 2rem;
            }

            @include media-breakpoint-down(lg) {
                width: 20%;
                margin-left: auto;
                justify-content: end;
            }
        }

        // SEARCH TOGGLER
        .search-toggler {
            line-height: 1rem;
            padding: 1.375rem 1rem;
            font-size: 0.875rem;
            text-transform: uppercase;
            font-weight: bold;

            @include media-breakpoint-down(xl) {
                font-size: 0.75rem;
            }

            @include media-breakpoint-down(lg) {
                font-size: 1rem;
                padding: 1rem 0;
            }

            &[aria-expanded="true"] {
                color: $turq;

                i:before {
                    content: "\e59b";
                }
            }

            @include media-breakpoint-down(xxl) {
                span {
                    display: none;
                }
            }
        }

        // LANGUAGE
        .language-selector {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            .language-item {
                line-height: 1rem;
                padding: 1.375rem 0;
                font-size: 0.875rem;
                text-transform: uppercase;
                font-weight: bold;
                text-decoration: none;
                transition: 0.15s ease-in-out;

                @include media-breakpoint-down(xl) {
                    font-size: 0.75rem;
                }

                @include media-breakpoint-down(lg) {
                    font-size: 0.875rem;
                    padding: 1rem 0;
                }

                &:first-child::before {
                    display: none;
                }

                &:hover,
                &.active {
                    text-decoration: underline;
                    color: $turq;
                }
            }
        }
    }

    // SEARCH FORM
    .search {
        position: absolute;
        top: 3.5rem;
        right: 0;
        left: 0;
        background: $color;
        width: 100%;
        margin: 0;
        box-shadow: 0 0.125rem 0.25rem rgba($black, 0.2);

        @include media-breakpoint-down(lg) {
            // position: relative;
            margin: 0;
            top: 3rem;
        }

        .search-form {
            display: flex;
            margin: 0;
            align-items: center;
            padding: 0.5rem 0;

            .form-control {
                border: 0;
                height: 3rem;
                font-size: 1.25rem;
                padding: 0;
                background: $color;
                color: $white;

                &::placeholder {
                    color: $light;
                }
            }

            .btn {
                display: flex;
                border: 0;
                border-left: 0;
                color: $white;
                background: $turq;
                width: 2.5rem;
                height: 2.5rem;
                margin-left: 1rem;
                transition: 0.15s;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                padding: .5rem 1rem;

                i {
                    font-size: 1rem;
                    margin: 0;
                }

                &:hover {
                    color: $white;
                    background: $turqalt;
                }

                &.close {
                    display: none;
                    background: $border;
                    color: $text;

                    &:hover {
                        color: $text;
                        background: $white;
                    }
                }
            }
        }
    }

    // Auto hide
    &.autohide {
        &.scrolled-down {
            transform: translateY(-100%);
            transition: all 0.3s ease-in-out;
        }

        &.scrolled-up {
            transform: translateY(0);
            transition: all 0.3s ease-in-out;
        }
    }
}


#login_register {
    margin-left: 30px;
    display: inline-block;

    @include media-breakpoint-down(md) {
        margin-left: 20px;
    }

    a {
        text-transform: uppercase;
        text-decoration: none;
    }

    .fa {
        font-size: 1.125rem;
        margin-right: 10px;
    }
}

.cart-customlocation {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 40px;

    @include media-breakpoint-down(lg) {
        width: 24px;
        height: 24px;
        margin-left: 20px;
    }

    &--full {
        .cart-customlocation__items {
            display: block;
            position: absolute;
            top: -10px;
            right: -12px;
            width: 20px;
            height: 20px;
            background: $color;
            border-radius: 50%;
            font-size: 12px;
            font-weight: bold;
            color: $white;
            text-align: center;
            line-height: 20px;
        }
    }

    &__items {
        display: none;
    }

    .fa {
        font-size: 1.5rem;
    }
}