.home {
	.page-header {

		.row {
			background: transparentize($turq, 0.8);
			padding-top: 2.5rem;
			padding-bottom: 2rem;
		}
	}
}

#shop-sidebar {
	padding: 4rem 2rem;
	background: $light;
	overflow: auto;
	transition: all 0.3s ease-out;

	@include media-breakpoint-down(xl) {
		width: calc(100% / 12 * 5);
	}

	@include media-breakpoint-down(lg) {
		width: calc(100% / 12 * 8);
		padding: 60px 30px 30px;
	}

	@include media-breakpoint-down(md) {
		width: calc(100% / 12 * 11);
	}

	aside {
		margin-bottom: 3rem;

		.widget-title {
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 800;
			letter-spacing: 0.2em;
			text-transform: uppercase;
			margin-bottom: 1.5rem;

			&__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 0 0 35px;
				height: 35px;
				margin-right: 15px;
				margin-left: -50px;
				border-radius: 50%;
				border: 1px solid var(--bs-body-color);

				svg {
					width: 16px;
					height: 16px;
				}
			}
		}

		&.closed {
			.widget-title:after {
				transform: translate(0, -50%) rotate(180deg);
			}
		}
		
		a {
			text-decoration: none;
		}
	}

	ul {
		list-style: none;
		padding: 0;
	}

	.widget_product_categories {
		text-transform: uppercase;

		li {
			display: flex;
			line-height: 1.35;

			&.current-cat {
				a {
					color: $color;
				}
			}

			a {
				margin-right: 10px;
			}

			.count {
				margin-left: auto;
			}
		}
	}

	.woocommerce-widget-layered-nav {

		&-list {
			display: flex;
			flex-wrap: wrap;

			li {
				display: inline-flex;
				flex: 0 1 50%;
				margin-bottom: 1rem;

				&.woocommerce-widget-layered-nav-list__item--chosen {
					a {
						color: $color;
						font-weight: 700;

						&:before {
							margin-left: -1.42em;
							margin-right: 0.418em;
							font-size: 0.875em;
							color: #c61d1d;
						}
					}
				}

				a {
					display: flex;
					align-items: center;
					line-height: 1.2;
					padding: 0 1rem 0 0;

					.term-icon {
						display: flex;
						align-items: center;
						justify-content: center;
						flex: 0 0 35px;
						height: 35px;
						margin-right: 15px;
						margin-left: -50px;
						border-radius: 50%;
						border: 1px solid var(--bs-body-color);

						svg {
							width: 16px;
							height: 16px;
						}
					}
				}

				.count {
					display: none;
					margin-left: auto;
				}
			}
		}

		&-dropdown {

			.select2-container--default .select2-selection--single {
				background: none;
				border: none;
				margin: 0;

				.select2-selection__placeholder {
					color: var(--bs-body-color);
				}

				.select2-selection__arrow {
					//background: embed-svg(arrow_down, var(--bs-body-color)) no-repeat center / 17px 10px;

					b {
						display: none;
					}
				}

				.select2-selection__clear {
					display: inline-block;
					width: 20px;
					height: 20px;
					//background: embed-svg('close', var(--bs-body-color)) no-repeat center / contain;
					float: none;
					text-indent: 110%;
					overflow: hidden;
					margin-right: 20px;
					margin-bottom: -5px;
				}
			}

			.select2-selection__rendered {
				padding: 0;
				border: none;
				border-radius: 0;
			}

			@at-root {
				.post-type-archive-product {

					.select2-dropdown {
						background: darken(#F3E4EB, 5%);
						border: none;
					}

					.select2-results__option {
						margin: 0 !important;
					}
				}
			}
		}
	}

	.widget_layered_nav_swatches {
		ul {
			margin-bottom: -10px;
		}

		li {
			display: inline-block;
			margin: 0 10px 10px 0;

			a {
				display: inline-block;
				width: 34px;
				height: 34px;
				border-radius: 50%;
				text-indent: 40px;
				overflow: hidden;
				white-space: nowrap;
				border: 2px solid transparent;
				margin: 0;
				box-shadow: 0 0 5px 2px #d7d7d7;

				&:before {
					display: none;
				}

				.color-swatch {
					display: inline-block;
					width: 30px;
					height: 30px;
					margin-left: -40px;
					border-radius: 50%;
				}
			}

			&.woocommerce-widget-layered-nav-list__item--chosen {
				a {
					border-color: $color;
				}
			}
		}

		.count {
			display: none;
		}
	}

	.tagcloud {

		ul {
			display: flex;
			flex-wrap: wrap;

			li {
				display: inline-flex;
				flex: 0 1 50%;
				padding-right: 1rem;
				margin-bottom: 1rem;
			}
		}
	}
}

.woocommerce {
	.products-grid {
		padding: 4rem calc(var(--bs-gutter-x) * 0.5);
	}
}

.woocommerce span.onsale {
	height: 3.236em;
	width: 3.236em;
	font-size: 0.957em;
	line-height: 2.736;
	background: $turq;
}

.woocommerce .widget_price_filter .price_slider {
	margin-bottom: 0.8em;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
	background-color: $turq;
	top: -0.28em;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
	background-color: $turq;
}

.woocommerce .widget_price_filter .price_label {
	text-align: left;
	margin-bottom: 0.6rem;
}

.woocommerce-product-search {
	display: flex;
	column-gap: 5px;

	button[type="submit"] {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}